body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
}


.h-100{
  height: 100%;
}

.h-90{
  height: 90%;
}

.w-100{
  width: 100%;
}