body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
}

.nav-part-1{
  height: 4rem;
}

.fs-xxl{
  font-size: 60px;
}

.h-100{
  height: 100%;
}

.w-100{
  width: 100%;
}

.profile-pic{
  width: 126px;
  height: 126px;
}

.scale-up-hor-center{
  animation:scale-up-hor-center .4s cubic-bezier(.39,.575,.565,1.000) alternate both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-5 17:37:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

 @keyframes scale-up-hor-center{0%{transform:scaleX(.4)}100%{transform:scaleX(1)}}