#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: gray;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: black;
}

.message-wrap{
  overflow-y: scroll;
  max-height: 80vh;
  min-height: 80vh;
}

.message-box-wrap{
  overflow-y: scroll;
  max-height: 74vh;
  min-height: 74vh;
}

.message-input-wrap{
  max-height: 6vh;
  min-height: 6vh;
}

.w-50{
  width: 50%;
}

.message-card{
  max-width: fit-content;
}